<template>
  <main>
    <div class="layout-subcontainer">
      <div class="box-wrap d-flex">
        <div class="col-6 pa-0">
          <h1 class="tit-h1 d-flex">파트너관리
            <div class="ml-auto">
              <v-btn outlined small class="btn-etc2 ml-2"  @click="getListData" id="btnSearch">조회</v-btn>
            </div>
          </h1>
          <div class="box-ct mt-2">
            <div class="table-form d-flex">
              <table>
                <caption class="hide">
                  파트너목록
                </caption>
                <colgroup>
                  <col width="148px" />
                  <col width="240px" />
                  <col width="148px" />
                  <col width="" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      <v-badge dot >건설사명</v-badge>
                    </th>
                    <td >
                      <div class="form-inp full">
                        <v-autocomplete
                          :items="ctcpTypeCd"
                          item-text="CD_NM"
                          item-value="CD"
                          outlined
                          @change="changeDetailBussType(CTCP_CODE)"
                          v-model="CTCP_CODE"
                          hide-details
                          placeholder="선택하세요."
                        >
                        </v-autocomplete>
                      </div>
                    </td>
                    <th scope="row">
                      <v-badge dot >사업지명</v-badge>
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-autocomplete
                          :items="bussTypeCd"
                          item-text="CD_NM"
                          item-value="CD"
                          outlined
                          v-model="BUSS_CODE"
                          :disabled="bussTypeCd.length == 0 ? true : false"
                          placeholder="선택하세요."
                        >
                        </v-autocomplete>
                      </div>
                    </td>
                  </tr> 
                  <tr>
                    <th scope="row" >
                      <v-badge dot >파트너ID</v-badge>
                    </th>
                    <td >
                      <div class="form-inp full">
                        <v-text-field
                          outlined
                          hide-details
                          v-model="S_USER_ID"
                          placeholder="파트너ID"
                          v-on:keyup.enter="getListData"
                        >
                        </v-text-field>
                      </div>
                    </td>
                  </tr>
                  
                </tbody>
              </table>
            </div>
          </div>
          <div class="box-ct mt-3">
            <h1 class="tit-h1 d-flex">파트너 목록
            </h1>
            <div class="mt-2">
              <v-data-table
                dense
                height='570px'
                :headers="gridDataHeaders"
                :items="gridDataText"
                :items-per-page="itemsPerPage"
                item-key="index"
                :page.sync="page"
                hide-default-footer
                class="grid-default"
                fixed-header
                @page-count="pageCount = $event"
                @click:row="gridRowClick"
                v-model="selGroupCd"
                :item-class="clickActive"
                no-data-text="검색된 결과가 없습니다."
              ></v-data-table>
              <div class="grid-paging text-center pt-2">
                <span class="grid-total-count">총 <strong>{{ gridDataText.length }}</strong>건</span>
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  :total-visible="totalVisible"
                  next-icon="svg-paging-next"
                  prev-icon="svg-paging-prev"
                ></v-pagination>
              </div>
            </div>
          </div>

        </div>

        <div class="ml-6 flex-grow-1">
          <h1 class="tit-h1 d-flex">파트너 상세내용
            <div v-if="USER_AUTH_CHK_BOOL" class="ml-auto">
              <v-btn
                outlined
                class="btn-default"
                @click="groupAdd"
              >
              소속등록
              </v-btn>
              <v-dialog  max-width="1200" content-class="square-modal min-auto" v-if="dialogCOP0410" persistent v-model="dialogCOP0410">
              <dialog-COP0410
                :param="COP0410Param"
                @hide="hideDialog('COP0410')"
                @submit="submitDialog('COP0410')"
              >
              </dialog-COP0410>
              </v-dialog>
              <v-btn
                outlined
                class="btn-default ml-2"
                @click="newBtnForm"
              >신규</v-btn
              >
              <v-btn
                outlined
                class="btn-point ml-2"
                @click="fnDelete"
              >삭제</v-btn
              >
              <v-btn
                outlined
                type="button"
                class="btn-etc ml-2"
                @click="fnRegist"
              >저장</v-btn
              >
            </div>
          </h1>
          <div class="box-ct mt-2">
            <div class="table-form d-flex">
              <table>
                <caption class="hide">
                  파트너 정보 항목
                </caption>
                <colgroup>
                  <col width="30%" />
                  <col width="30%" />
                  <col width="30%" />
                  <col width="30%" />
                  <col width="30%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      <v-badge dot color="#EF9191" v-if="new_Type === 'INSERT'">파트너ID</v-badge>
                      <label v-else>파트너ID</label>
                    </th>
                    <td style="width:100%">
                      <div class="form-inp full">
                        <v-textarea
                          width:500px
                          class="form-inp full"
                          counter
                          no-resize
                          outlined
                          :disabled="dis_userId"
                          v-model="T_USER_ID"
                        ></v-textarea>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <v-badge dot color="#EF9191" v-if="new_Type === 'INSERT'">비밀번호</v-badge>
                      <label v-else>비밀번호</label>
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-text-field v-if="new_Type === 'UPDATE'"
                        class="form-inp full"
                        type="password"
                        maxLength="20"
                        outlined
                        placeholder="비밀번호 변경 필요 시 입력하세요."
                        :disabled="dis_userPassword"
                        v-model="T_USER_PASSWORD"
                        ></v-text-field>
                        <v-text-field v-else
                        class="form-inp full"
                        type="password"
                        maxLength="20"
                        outlined
                        :disabled="dis_userPassword"
                        v-model="T_USER_PASSWORD"
                        ></v-text-field>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      파트너명
                    </th>
                    <td>
                      <v-textarea
                        class="form-inp full"
                        counter
                        no-resize
                        outlined
                        :disabled="dis_userNm"
                        v-model="T_USER_NM"
                        maxlength="10"
                      ></v-textarea>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <v-badge dot color="#EF9191" v-if="new_Type === 'INSERT' || new_Type === 'UPDATE'">권한그룹명</v-badge>
                      <label v-else>권한그룹명</label>
                    </th>
                    <td>
                      <v-select
                        class="form-inp full"
                        :items="T_USER_GROUP_CD_ITEMS"
                        item-text="CD_NM"
                        item-value="CD"
                        outlined
                        hide-details
                        required
                        label="선택하세요"
                        v-model="T_USER_GROUP_CD"
                        :disabled="!USER_AUTH_CHK_BOOL"
                      >
                      </v-select>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" color="#EF9191">사용여부</th>
                    <td>
                      <v-select
                        class="form-inp full"
                        :items="dropUseYn"
                        item-text="CD_NM"
                        item-value="CD"
                        outlined
                        hide-details
                        required
                        v-model="T_USE_YN"
                        :disabled="dis_UseYn"
                      >
                      </v-select>
                    </td> 
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>


<script>
  import GibberishAes_mixin from "@/mixin/gibberish-aes_mixin.js";
  import DialogCOP0410 from "./COP0410.vue";

  export default {
    name: "MENU_COM0400", //name은 'MENU_' + 파일명 조합
    components: {
      DialogCOP0410,
    },
    data() {
      return {
        selGroupCd : [],
        COP0410Param : {},
        dis_userId : true,
        dis_userPassword : true,
        dis_userNm : true,
        dis_UseGroupNm : true,
        dis_UseYn : true,
        dialogCOP0410: false,
        menu1: false,
        menu2: false,
        dates: [
          '',
          '',
        ],

        new_Type: [],
        groupNm:[],
        useYn:[],
        bussTypeCd:[],
        ctcpTypeCd:[],
        allCodeList : [],
        dropUseYn: [],

        gridDataHeaders: [
          { text: "No",       value: "index",  align: "center", width: "50px", sortable: true  },
          { text: "파트너ID",  value: "USER_ID", align: "center", width: "120px", sortable: true },
          { text: "파트너명",  value: "USER_NM", align: "center", width: "120px", sortable: true },
          { text: "권한그룹명", value: "USER_GROUP_NM", align: "center", width: "120px", sortable: true },
          { text: "사용여부",   value: "USE_YN", align: "center", width: "80px", sortable: true },
          { text: "권한그룹코드", value: "USER_GROUP_CD",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: "사용여부코드",   value: "CD_USE_YN",  width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
        ],

        gridDataText: [],
        selectedItem: {},
        page: 1,
        pageCount: 0,
        itemsPerPage: 30,
        totalVisible: 10,

        //v-model
        S_USER_ID:'',
        T_USER_ID:'',
        T_USER_PASSWORD:'',
        T_USER_NM:'',
        T_USER_GROUP_CD_ITEMS : [],
        T_USER_GROUP_CD :'',
        T_USE_YN : '',
        CTCP_CODE: '',
        BUSS_CODE: '',
        ENCRYPT_KEY: '',
        ORG_ENCRYPT_KEY: '',
        GE_USER_ID:'',
        USER_AUTH_CHK:'',
        USER_AUTH_CHK_BOOL:false,
        RDOnly:false,

        BTN_TY: '',
      };

    },

    methods: {

      activeRow(row, value){
        this.selectedRow = row;
        row.select(Boolean(value));
        row.isSelected = Boolean(value);
      },
      
      disableRow(row, value){
        row.select(Boolean(value));
        row.isSelected = Boolean(value);
      },

      gridRowClick (item, row) {
        if(item != null){
          if (row.isSelected == false){
            this.activeRow(row,true);
          } else {
            this.disableRow(row,false);
          }
        }

        this.new_Type = "UPDATE";

        this.T_USER_ID       = item.USER_ID;
        this.T_USER_NM       = item.USER_NM;
        this.T_USER_GROUP_CD = item.USER_GROUP_CD;
        this.T_USE_YN        = item.CD_USE_YN;
        this.ENCRYPT_KEY     = item.ENCRYPT_KEY;
        this.ORG_ENCRYPT_KEY = item.ENCRYPT_KEY;

        this.disabledActive("part");

      },

      clickActive(item){
        //클릭 시, 하이라이트
        return item.USER_ID == this.T_USER_ID ? 'active' : '';
      },

      //**********************************
      //      팝업 호출 시
      //**********************************
      groupAdd(){

        if( this.T_USER_ID =='' ) {
          this.$store.commit("alertStore/openAlertDialog", {
            alertDialogToggle: true,
            msg: "선택된 항목이 존재하지 않습니다.",
            iconClass: "svg-error-lg",
            type: "default",
          });
          return;
        }

        this.COP0410Param = {
          userId: this.T_USER_ID,
        };
  
        this.showDialog('COP0410');

      },


      //**********************************
      //      신규버튼 클릭시
      //**********************************
      async newBtnForm(){
        this.cleanValidation();
        this.disabledActive("false");
        this.new_Type="INSERT";
        this.T_USE_YN = this.dropUseYn[1].CD;
      },

      //**********************************
      //      고객중복 조회 호출
      //**********************************
      async getListData(){

        if(!this.USER_AUTH_CHK_BOOL && this.mixin_isEmpty(this.CTCP_CODE)) {
          this.common_alert('건설사를 선택해주세요.', 'chk');
          return;
        }
        if(!this.USER_AUTH_CHK_BOOL && this.mixin_isEmpty(this.BUSS_CODE)) {
          this.common_alert('사업지를 선택해주세요.', 'chk');
          return;
        }

        this.new_Type="";

        let requestData = {
            headers: {},
            sendData:{},
        };

        //header 세팅
        requestData.headers["URL"] = "/api/setting/board/user-manage/list";
        requestData.headers["SERVICE"] = "setting.board.user-manage";
        requestData.headers["METHOD"] = "list";
        requestData.headers["ASYNC"] = false;

        //sendData 초기화
        requestData.sendData["CTCP_CODE"] = this.CTCP_CODE;
        requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;
        requestData.sendData["USER_ID"]   = this.S_USER_ID;

        //선택된 데이터 초기화
        //this.checkedList = [];

        let response = await this.common_postCall(requestData);

        this.result(response);

        //클릭한 스크립트
        this.cleanValidation();
        this.disabledActive();
      },

      fnRegist() {
        this.clickBtnForm('UPDATE');
      },

      fnDelete() {
        this.clickBtnForm('DELETE');
      },

      //***************************************
      //   모달 이벤트(등록, 수정, 삭제)
      //***************************************
      async clickBtnForm(btn) {

        this.BTN_TY = btn;

        if(this.new_Type == "INSERT" && this.BTN_TY != "DELETE") {
          if(this.mixin_isEmpty(this.T_USER_ID)) {
            this.common_alert('파트너ID를 입력해주세요.', 'chk');
            return;
          }
          if(this.T_USER_ID.search(/\s/) != -1) {
            this.common_alert('파트너ID에 공백이 들어갈 수 없습니다.', 'chk');
            return;
          }
          if(this.mixin_isEmpty(this.T_USER_PASSWORD)) {
            this.common_alert('비밀번호를 입력해주세요.', 'chk');
            return;  
          }
          if(this.mixin_isEmpty(this.T_USER_GROUP_CD)) {
            this.common_alert('권한그룹명을 입력해주세요.', 'chk');
            return;  
          }
          if(this.mixin_isEmpty(this.T_USE_YN)) {
            this.common_alert('사용여부를 입력해주세요.', 'chk');
            return;  
          }
        } else {
          if(this.mixin_isEmpty(this.T_USER_ID)) {
            this.common_alert('선택된 항목이 존재하지 않습니다.', 'chk');
            return;
          }
        }

        //암호가 입력된경우 입력값에 대한 유효성 체크
        if (!this.mixin_isEmpty(this.T_USER_PASSWORD)) {
          if(!this.mixin_pwdChk(this.T_USER_PASSWORD)) {
            this.common_alert('비밀번호는 영문,숫자,특수문자를 포함 공백없이 4~20자 이내로 기입해주세요.', 'chk');
            return;
          }
        }else{
          this.T_USER_PASSWORD = '';
        }

        let delChk = "";
        if(this.new_Type == "INSERT") {
          delChk= "파트너를 등록 하시겠습니까?";
        }else{
          if (this.BTN_TY == "DELETE"){
            delChk= "파트너를 삭제하시겠습니까?";
          } else if (this.BTN_TY == "UPDATE") {
            delChk= "파트너를 수정 하시겠습니까?";
          }
        }

        if(!this.mixin_isEmpty(this.T_USER_PASSWORD)) {
          //암호화키 셋팅
          await this.getKey();
        }

        this.common_confirm(delChk, this.clickBtnReg, null, null, null, "chk");
      },

      async clickBtnReg() {

        let URLData = "/api/setting/board/user-manage/insert";
        let HEADER_SERVICE = "setting.board.user-manage";
        let HEADER_METHOD = "regist";
        let HEADER_TYPE = "BIZ_SERVICE";

        if (this.new_Type != "INSERT"){
          if (this.BTN_TY == "DELETE"){
            URLData = "/api/setting/board/user-manage/delete";
            HEADER_SERVICE = "setting.board.user-manage";
            HEADER_METHOD = "delete";
          } else if (this.BTN_TY == "UPDATE"){
            URLData = "/api/setting/board/user-manage/update";
            HEADER_SERVICE = "setting.board.user-manage";
            HEADER_METHOD = "update";
          }
        }

        let insertList = [];

        let pwd = '';
        if(!this.mixin_isEmpty(this.T_USER_PASSWORD)) {
          pwd = this.common_aesEncrypt(this.T_USER_PASSWORD, this.ENCRYPT_KEY);
        }

        let obj = {
          BTNTYPE       : this.BTN_TY,                                                            //버튼타입 
          REGR_ID       : this.user_id,             //사용자ID 
          USER_ID       : this.T_USER_ID,                                                   //사용자ID
          PASSWORD      : pwd,   //사용자비밀번호
          ENCRYPT_KEY   : this.ENCRYPT_KEY,                                                 //사용자암호화키
          USER_NM       : this.T_USER_NM,                                                   //고객명
          USER_GROUP_CD : this.T_USER_GROUP_CD,                                             //권한그룹명
          USE_YN        : this.T_USE_YN,                                                    //사용여부
        };

        insertList.push(obj);

        let requestData = {
          headers: {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
          },
          sendData: {
            DATA: insertList
          },
        };

        requestData.headers["URL"] = URLData;
        requestData.headers["ASYNC"] = false;

        let response = await this.common_postCall(requestData);

        if(response.HEADER.ERROR_FLAG) {
          let msg = '저장에 실패하였습니다.';
          if(!this.mixin_isEmpty(response.HEADER.ERROR_MSG)) {
            msg = response.HEADER.ERROR_MSG;
          }
          this.common_alert(msg, "error");
        } else {
          this.common_alert("정상적으로 처리되었습니다.");
          this.getListData();
        }
      },

      //암호화
      common_aesEncrypt(strPassWD, strEncryptKey) {
        return GibberishAes_mixin.aesEncrypt(strPassWD, strEncryptKey);
      },

      //암호화 키 호출
      async getKey() {
        //암호화 키가 없거나 특정건 선택한후 암호화된 상태의 키일경우
        if(this.ENCRYPT_KEY == '' || (this.ORG_ENCRYPT_KEY == this.ENCRYPT_KEY)) {

          let requestData = {
            headers: {},
            sendData: {
              USER_ID: this.T_USER_ID,
              USER_NAME: this.T_USER_NM,
              encryptKey: this.ENCRYPT_KEY,
            },
          };

          requestData.headers["URL"] = "/api/login/password-encpt/process";
          requestData.headers["SERVICE"] = "";
          requestData.headers["METHOD"] = "list";
          requestData.headers["ASYNC"] = false;

          let response = await this.common_postCall(requestData);

          if(response.HEADER.ERROR_FLAG) {
            this.common_toast("관리자에게 문의해주세요.", "error");
            return false;
          }else{
            if (!this.mixin_isEmpty(response.DATA)) {
              this.ENCRYPT_KEY = response.DATA[0].ENCRYPT_KEY;
            } else {
              this.common_toast("관리자에게 문의해주세요.", "chk");
            }
          }
        }
      },

      cleanValidation() {
        this.T_USER_ID       = "";
        this.T_USER_GROUP_CD = "";
        this.T_USE_YN        = "";
        this.T_USER_NM       = "";
        this.T_USER_PASSWORD = "";
        this.ENCRYPT_KEY     = "";
        this.ORG_ENCRYPT_KEY = "";
      },

      //상세내용 활성화,비활성화
      disabledActive(item){
        if (item == "false"){
          //상세내용비활성화
          this.dis_userId       = false;      //파트너ID
          this.dis_userPassword = false;      //파트너비밀번호
          this.dis_userNm       = false;      //파트너명
          this.dis_UseGroupNm   = false;      //권한그룹명
          this.dis_UseYn        = false;      //사용여부
        }else if (item == "part"){
          this.dis_userPassword = false;      //파트너비밀번호
          this.dis_userNm       = false;      //파트너명
          this.dis_UseGroupNm   = false;      //권한그룹명
          this.dis_UseYn        = false;      //사용여부
        }else{
          this.dis_userId       = true;       //파트너ID
          this.dis_userPassword = true;       //파트너비밀번호
          this.dis_userNm       = true;       //파트너명
          this.dis_UseGroupNm   = true;       //권한그룹명
          this.dis_UseYn        = true;       //사용여부
        }
      },



      getDetailListCallBack(response){
        let header = response.HEADER;
        let data = response.DATA;
        if(!this.mixin_isEmpty(response) && !header.ERROR_FLAG){
          if(!this.mixin_isEmpty(data) && data.length > 0) {
            for (let d of data) {
              for (let k of Object.keys(d)) {
                d[k] = this.mixin_isEmpty(d[k]) ? '' : d[k];
              }
              d['index'] = this.gridDataText.length + 1;
              this.gridDataText.push(d);
            }
          }
        }else {
          this.common_alert(`관리자에게 문의해주세요.`, 'error');
        }

      },

      result(res){
        let header= res.HEADER;
        this.totcount = header.TOT_COUNT;

        let data = res.DATA;
        this.gridDataText = data;

        let idx = 1;
        for(var i in this.gridDataText){
          this.gridDataText[i]["index"] = idx++;
        }
        
      },

      //사업지 코드 가져오기
      async changeDetailBussType(ctcpTypeCd) {
        this.bussTypeCd = [];
        this.BUSS_CODE = "";

        if (!this.mixin_isEmpty(ctcpTypeCd)) {
          if (this.USER_AUTH_CHK_BOOL){
            this.bussTypeCd = await this.mixin_busscode_get(ctcpTypeCd);
          }else{
            this.bussTypeCd = await this.mixin_busscode_get(ctcpTypeCd, this.GE_USER_ID);
          }
        }
      },
      showDialog(type) {
        this[`dialog${type}`] = true;
      },

      hideDialog(type) {
        this[`dialog${type}`] = false;
      },

      submitDialog(type) {
        this.gridDataText = [];
        this.selGroupCd = [];
        this[`dialog${type}`] = false;
      },

      resetDialog(type) {
        this.gridDataText = [];
        this.selGroupCd = [];
        this[`dialog${type}`] = false;
      },

      unlockDialog(type) {
        this.gridDataText = [];
        this.selGroupCd = [];
        this[`dialog${type}`] = false;
      },
    },
    async mounted() {
      let userGroupCd = this.user_role.userGroupCd;
      this.GE_USER_ID = this.user_role.userId;

      this.cleanValidation();
      this.disabledActive();

      let pArr = ['HLW_USE_YN','HLW_PLT_AUTH','HLW_CTCP_CD','HLW_PLT_FILD_AUTH'];

      this.allCodeList = await this.mixin_common_code_get_all(pArr);

      this.dropUseYn = await this.mixin_common_code_get(this.allCodeList, 'HLW_USE_YN');
      this.T_USER_GROUP_CD_ITEMS = await this.mixin_common_code_get(this.allCodeList,'HLW_PLT_AUTH');

      if (userGroupCd =="SYSTEMMANAGER"){
        this.USER_AUTH_CHK_BOOL = true;
        this.USER_AUTH_CHK = userGroupCd;  
        this.ctcpTypeCd = await this.mixin_common_code_get(this.allCodeList,'HLW_CTCP_CD','전체');
      }else{
        this.USER_AUTH_CHK_BOOL = false;
        let usserAuth = await this.mixin_buss_ctcp_code_user_auto(this.GE_USER_ID);
        
        usserAuth.forEach((element, i) => {
          this.ctcpTypeCd.push({ CD_NM: element.CTCP_NAME, CD: element.CTCP_CODE });
          this.bussTypeCd.push({ CD_NM: element.BUSS_NAME, CD: element.BUSS_CODE });
        });

        if(!this.mixin_isEmpty(this.ctcpTypeCd)) {
          this.CTCP_CODE = this.ctcpTypeCd[0].CD;

          if(!this.mixin_isEmpty(this.bussTypeCd) && !this.mixin_isEmpty(this.bussTypeCd[0].CD)) {
            this.BUSS_CODE = this.bussTypeCd[0].CD;
          }
        }
      }
    },

    computed: {
      initHeaders(){
        return {
          SERVICE : 'setting.agent.manage',
          METHOD : "",
          TYPE : 'BIZ_SERVICE',
        };
      },
    },
  };
</script>

<style>
  thead.v-data-table-header tr th {
    text-align-last: center;
  }
</style>
