<template>
  <v-card>
    <v-card-title>
      소속등록
      <!-- 창 닫기 아이콘 -->
      <div class="ml-auto align-self-center">
          <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
        </div>
    </v-card-title>
    <v-card-text>
      <template>
   
        <div class="search-box">
          <table>
            <colgroup>
              <col style="width:150px">
              <col style="width:150px">
              <col style="width:200px">
              <col style="width:200px">
            </colgroup>
            <tbody>
              <tr>   
                <td>
                  <div class="form-inp ml-3">
                  <v-select
                    class="form-inp full"
                    :items="ctcpTypeCd"
                    item-text="CD_NM"
                    item-value="CD"
                    outlined
                    hide-details
                    label="건설사명"
                    placeholder="선택하세요"
                    @change="changeDetailBussType(CTCP_CD)"
                    v-model="CTCP_CD"
                    :disabled="dis_ctcpTypeCd"
                  >
                  </v-select>
                  </div>
                </td>
                <td>
                  <div class="form-inp ml-3">
                    <v-select
                      class="form-inp full"
                      :items="bussTypeCd"
                      item-text="CD_NM"
                      item-value="CD"
                      outlined
                      hide-details
                      label="사업지명"
                      placeholder="선택하세요"
                      :disabled="bussTypeCd.length == 0 ? true : false"
                      v-model="BUSS_CODE"
                    ></v-select>
                  </div>
                </td>    
                <td>
                  <div class="form-inp ml-3">
                  <v-text-field
                    class="form-inp full"
                    name="SORT_ORD"
                    type="number"
                    id="sortOrd"
                    min="1"
                    max="9999"
                    v-model.number.lazy="SORT_ORD"
                    hide-details
                    outlined
                    placeholder="정렬순서"
                    :disabled="dis_sortOrd"
                  ></v-text-field>
                  </div>
                </td>
                  <td style="display:none;">
                  <div class="form-inp ml-3">
                  <v-text-field
                    class="form-inp full"
                    name="USER_ID"
                    id="userID"
                    v-model="USER_ID"
                    hide-details
                    outlined
                    placeholder="파트너ID"
                  ></v-text-field>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="m2-1 flex-grow-1">
          <h1 class="tit-h1 d-flex" style="padding: 20px 0 10px 0;">
            사업지 상세
            <!-- 신규 버튼, 삭제 버튼, 저장 버튼 -->
            <div class="ml-auto align-self-center">
              <v-btn outlined small class="btn-default ml-2" @click="clickBtnAction('INSERT')">신규</v-btn>
              <v-btn outlined small class="btn-point ml-2" @click="clickBtnForm('DELETE')">삭제</v-btn>
              <v-btn outlined small class="btn-etc ml-2" @click="clickBtnForm('UPDATE')">저장</v-btn>
            </div>
          </h1>

          <div class="box-ct" style=" border-collapse : collapse;">
            <v-data-table
              class="grid-default"
              :headers="gridDataHeaders"
              :items="gridDataText"
              v-model="gridListSelected"
              :items-per-page="itemsPerPage"
              item-key="ROW_NUMBER"
              hide-default-footer
              calculate-widths
              height="370px"
              dense
              @click:row="getScriptDetail"
              @dblclick:row="dbClickData"
              :item-class="clickActive"
              no-data-text="검색된 결과가 없습니다."
            >
            </v-data-table>
            <p class="caption text-left">총<strong class="clr-error">{{ gridDataText.length }}</strong>건</p>
          </div>
        </div>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: "MENU_COP0410", //name은 'MENU_' + 파일명 조합
    props: {
      param: {
        type: Object,
      },
      headerTitle: {
        type: String,
        default: ''
      },
    },

    data() {
      return{
        gridDataText: [],
        gridListSelected: [],
        asp_cust_key: [],
        new_Type: [],
        CTCP_NAME: '',
        BUSS_NAME: '',
        SORT_ORD:1,
        USER_ID:'',
        BF_USER_ATTR_A: '',
        BF_USER_ATTR_B: '',
        ctcpTypeCd: [],       
        bussTypeCd: [],      
        allCodeList : [],
        CTCP_CD: '',
        BUSS_CODE: '',

        selectedItem: {},

        gridDataHeaders: [
          { text: 'No',     		 value: 'ROW_NUMBER' ,          width: '40px',  align: 'center', sortable: false  },
          { text: "건설사명",     value: "CTCP_NAME",       width: "200px",  align: "center",  sortable: false},
          { text: "사업지명",     value: "BUSS_NAME",      width: "200px",   align: "center",  sortable: false}, 
          { text: "정렬순서",     value: "SORT_ORD",           width: "70px",  align: "center",  sortable: false},

          { text: '사용자ID',      value: 'USER_ID',             width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: '건설사코드',    value: 'CTCP_CODE',        width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
          { text: '사업지코드',    value: 'BUSS_CODE',        width: '0px' ,  align: 'center', sortable: false ,  "align": " d-none" , show:false },
        ],
        itemsPerPage: 10,
        
        dis_ctcpTypeCd: true,
        dis_bussTypeCd: true,
        dis_sortOrd: true,
      }
    },

    methods:{

      //**********************************
      //      소속 조회 호출
      //**********************************
      async getListData(){

        let requestData = {
            headers: {},
            sendData:{},
        }

        //header 세팅      
        requestData.headers["URL"] = "/api/setting/board/user-dept-manage/list";
        requestData.headers["SERVICE"] = "setting.board.user-dept-manage";
        requestData.headers["METHOD"] = "list";
        requestData.headers["ASYNC"] = false;

        //sendData 초기화
        requestData.sendData["USER_ID"]   = this.USER_ID;

        let response = await this.common_postCall(requestData);

        //선택된 데이터 초기화
        this.gridListSelected = []; 
        this.ctcpTypeCd = [];
        this.bussTypeCd = [];
        this.new_Type = "";
        this.SORT_ORD = 1;

        let data = response.DATA;

        if(!response.HEADER.ERROR_FLAG){
          if(!this.mixin_isEmpty(data)){
            this.gridDataText = data;
            let idx = 1;
            for(var i in this.gridDataText){
              this.gridDataText[i]["ROW_NUMBER"] = idx++;
            }
  


          } else {
            this.gridDataText = [];
          }
          
        
        } else {
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
        }


      },

  
      //***************************************
      //   모달 이벤트(등록, 수정, 삭제)
      //***************************************

      async clickBtnAction(btn) {

        this.ctcpTypeCd = [];
        this.bussTypeCd = [];
        this.srhCnslTypCd3 = [];
        this.CTCP_NAME = "";
        this.BUSS_NAME = "";
        this.SORT_ORD = 1;
        this.disabledActive("false");
        this.CTCP_CD = '';
        this.BUSS_CODE = '';
        this.new_Type=btn;
        this.selectedCtcp();
      },
      insertValidation() {
        if (this.CTCP_CD == '' || this.CTCP_NAME == '전체' ) return false;
        if (this.BUSS_CODE == '' || this.BUSS_NAME == '전체') return false;
        if (this.mixin_isEmpty(this.SORT_ORD) || this.SORT_ORD < 1) return false;

        return true;
      },

      async getScriptDetail(item) {

        this.disabledActive("false");
        this.selectedCtcp(item);
        this.changeDetailBussType(item.CTCP_CODE);
        this.selectedItem = item;
        this.CTCP_NAME = item.CTCP_NAME;
        this.BUSS_NAME = item.BUSS_NAME;
        this.CTCP_CD = item.CTCP_CODE;
        this.BUSS_CODE = item.BUSS_CODE;
        this.BF_USER_ATTR_A = item.CTCP_CODE;
        this.BF_USER_ATTR_B = item.BUSS_CODE;
        this.SORT_ORD = item.SORT_ORD;
        this.new_Type = "UPDATE" 
        
        //this.rowData();

      },
      clickActive(item){
      
        //클릭 시, 하이라이트
        return item.ROW_NUMBER == this.selectedItem.ROW_NUMBER ? 'active' : '';
      },

      //***************************************
      //   모달 이벤트(등록, 수정, 삭제)
      //***************************************
      clickBtnForm(btn) {

        let delChk = "";

        if (this.new_Type == "INSERT"){
          
          if (!this.insertValidation()) {
            this.common_alert('소속을 모두 입력해주세요.', 'error');
            return;
          }

          delChk= "파트너 소속을 등록 하시겠습니까?";
          btn = this.new_Type;

        } else {

          if(( this.USER_CNSL_TYP_NO== '') ) {
            this.common_alert(`파트너 소속을 선택해주세요.`, 'error')
            return false
          }

          if (btn == "DELETE"){
            delChk= "파트너 소속을 삭제하시겠습니까?";
          } else if (btn == "UPDATE") {
            if (!this.insertValidation()) {
              this.common_alert('파트너 소속을 모두 입력해주세요.', 'error');
              return;
            }
            delChk= "파트너 소속을 수정 하시겠습니까?";
          }
        }

        this.common_confirm(delChk, this.clickBtnRegY, btn, null, null, "error");
      },

      async clickBtnRegY(btn) {
      
        let URLData = "/api/setting/board/user-dept-manage/insert";
        let HEADER_SERVICE = "setting.board.user-dept-manage";
        let HEADER_METHOD = "regist";
        let HEADER_TYPE = "BIZ_SERVICE";

        if (btn == "DELETE"){
          URLData = "/api/setting/board/user-dept-manage/delete";
          HEADER_SERVICE = "setting.board.user-dept-manage";
          HEADER_METHOD = "delete";
        } 

        let insertList = [];

        let obj = {
          BTNTYPE          : btn  ,                                                             //버튼타입   
          REGR_ID          : this.user_id,                 //사용자ID 
          USER_ID          : this.USER_ID,                                                      //사용자ID 
          USER_ATTR_A      : this.CTCP_CD,                                                  //건설사코드
          USER_ATTR_B      : this.BUSS_CODE,                                                  //사업지코드
          SORT_ORD         : this.SORT_ORD,                                                     //정렬순서
          SORT_ORD_CHK     : ""                                                                 //정렬순서유무
        }; 

        if (btn == "UPDATE") {

          if(this.BF_USER_ATTR_A == this.CTCP_CD && this.BF_USER_ATTR_B == this.BUSS_CODE ){

            obj = {
              BTNTYPE          : btn  ,                                                             //버튼타입   
              USER_ID          : this.USER_ID,                                                      //사용자ID 
              AMDR_ID          : this.user_id,                 //사용자ID 
              
              USER_ATTR_A      : this.CTCP_CD,                                                  //건설사코드
              USER_ATTR_B      : this.BUSS_CODE,                                                  //사업지코드
              BF_USER_ATTR_A   : this.BF_USER_ATTR_A,
              BF_USER_ATTR_B   : this.BF_USER_ATTR_B,
              SORT_ORD         : this.SORT_ORD,                                                     //정렬순서
              SORT_ORD_CHK     : "Y"                                                                //정렬순서유무
            };

          } else {

            obj = {
              BTNTYPE          : btn  ,                                                             //버튼타입   
              USER_ID          : this.USER_ID,                                                      //사용자ID 
              AMDR_ID          : this.user_id,                 //사용자ID 
              
              USER_ATTR_A      : this.CTCP_CD,                                                  //건설사코드
              USER_ATTR_B      : this.BUSS_CODE,                                                  //사업지코드
              BF_USER_ATTR_A   : this.BF_USER_ATTR_A,
              BF_USER_ATTR_B   : this.BF_USER_ATTR_B,
              SORT_ORD         : this.SORT_ORD,                                                     //정렬순서
              SORT_ORD_CHK     : "Y"                                                                //정렬순서유무
            };
          }
        }    

        insertList.push(obj);

        let requestData = {
          headers: {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
          },
          sendData: {
            DATA: insertList
          },
        };

        requestData.headers["URL"] = URLData;
        requestData.headers["ASYNC"] = false;

        let response = await this.common_postCall(requestData);

        if(response.HEADER.ERROR_FLAG) {
          let msg = "";
          if(response.HEADER.ERROR_MSG.includes("파트너소속이 이미 존재합니다.")) {
            msg = "이미 동일한 파트너소속이 존재합니다.";
          }
          this.common_alert(msg, "error");
        }else{
          this.common_alert("정상적으로 처리되었습니다.");
          this.getListData(); 
          this.disabledActive();
        }
      },
          //사업지 코드 가져오기
      async changeDetailBussType(ctcpTypeCd) {


        this.bussTypeCd = [];
        this.bussTypeCd.push({ CD: "", CD_NM: '' });

        if (ctcpTypeCd !== '') {
          this.bussTypeCd = await this.mixin_busscode_get(ctcpTypeCd);


        }else{
          this.common_alert(`건설사 코드를 선택 하세요.`, 'error')
        }
      },
      clickMuDelN() {
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: false,
        });
      },
      async selectedCtcp(item){
        let pArr = ['HLW_CTCP_CD'];

        this.allCodeList = await this.mixin_common_code_get_all(pArr);
        this.ctcpTypeCd = await this.mixin_common_code_get(this.allCodeList,'HLW_CTCP_CD');

        if(!this.mixin_isEmpty(item)) {
          for(let i = 0; i < this.ctcpTypeCd.length; i++) {
            if(this.ctcpTypeCd[i].CD === item.CTCP_CODE) {
              this.CTCP_CD = this.ctcpTypeCd[i].CD;
            }
          }
        }
      },
      selectedSurvey(item){
        let params = {
          CTCP_NAME : item.CTCP_NAME,
          BUSS_NAME : item.BUSS_NAME,
      
        }

        this.$emit('selected', params);
        this.$emit('hide');
      },
      dbClickData(row, {item}){
        //더블 클릭 시, 설문지 선택 되도록
        this.selectedSurvey(item);
      },
      async setSrhDetailCnslType() {

        this.ctcpTypeCd = [];
        this.bussTypeCd = [];

        this.ctcpTypeCd = await this.mixin_counsel_type_code_get(this.asp_cust_key, "*");

      },
      async rowData() {
      this.setSrhDetailCnslType();
      if (this.CTCP_NAME != '' && this.CTCP_NAME != undefined) {
        this.changeDetailCounselType(this.asp_cust_key, this.CTCP_NAME, '1');
        if (this.BUSS_NAME != '' && this.BUSS_NAME != undefined) {
          this.changeDetailCounselType(this.asp_cust_key, this.BUSS_NAME, '2');

        }
      }

      },

      // 상세내용 활성화,비활성화
      disabledActive(item){
        if(item === "false") {
          // 상세내용비활성화
          this.dis_ctcpTypeCd   = false;      // 건설사
          this.dis_sortOrd      = false;      // 정렬순서
        }else {
          this.dis_ctcpTypeCd   = true;       // 건설사
          this.dis_sortOrd      = true;       // 정렬순서
        }
      },
    },
    async mounted() {

      this.USER_ID         = this.param.userId;

      this.getListData();


    },

    computed: {


    },

  };
</script>

<style></style>
